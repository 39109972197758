@import "@Static/css/common";

.template {
    // Default theming
    &:not(.elementary):not(.classic):not(.eduverse):not(.unlock) {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
                "tc": (
                    "key": 2,
                ),
            ),
            "schema1"
        );
    }

    // Template specific theming
    &.elementary {
        background-color: #f8f7f2dd;
        backdrop-filter: blur(6px);
    }
    &.eduverse {
        background-color: rgb(var(--template-header-background-host-theme));
        color: rgb(var(--template-header-text-host-theme));
    }
    &.classic {
        background-color: rgb(var(--template-footer-background-host-theme));
        color: rgb(var(--template-footer-text-host-theme));
    }
    &.unlock {
        background-color: rgb(var(--template-header-background-host-theme));
        color: rgb(var(--template-header-text-host-theme));
    }
}

.root {
    padding: 24px 36px 16px;
    position: relative;
    @extend .template;
}
.container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom_footer_container {
    max-width: 1440px;
    margin: 0 auto;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 4px;
    &:first-of-type {
        text-align: left;
    }
    &:last-of-type {
        text-align: right;
    }
    a {
        width: fit-content;
        &,
        &:hover {
            color: inherit;
        }
    }
    div,
    a {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.64px;
        margin: 5px 0;
    }
    .flexGrow {
        flex-grow: 1;
    }
    .brand {
        font-weight: 700;
        letter-spacing: 0.4px;
    }
}
.customFooterText {
    * {
        font-size: 16px;
        padding: 0;
        margin: 5px 0;
        font-weight: 500;
        letter-spacing: -0.64px;
    }
}

@media only screen and (max-width: 768px) {
    .template {
        &.classic {
            padding-bottom: 56px;
        }
    }
    .root {
        padding: 20px 12px 16px;
        @extend .template;
    }
    .column {
        div,
        a {
            font-size: 14px;
            letter-spacing: -0.8px;
        }
        min-width: clamp(80px, 30%, 150px);
    }
    .customFooterText {
        * {
            font-size: 14px;
            letter-spacing: -0.8px;
        }
    }
}
