@import "@Static/css/common";
$color1: getColorValue(1, (56, 36, 242));

.eventSectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1240px;
    margin: auto;
}

.events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.loadMore {
    color: $color1;
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;
    margin: 8px 0 -8px;

    transition: 0.2s ease all;
    display: flex;
    align-items: center;

    &:hover {
        transform: scale(1.02);
        text-decoration: underline;
    }
    
    .loadIcon {
        font-size: 20px;
        margin-top: 8px;
        margin-left: 4px;
    }
}
