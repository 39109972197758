@import "@Static/css/common";

.socialIconsContainer {
    display: flex;
    &:not(.yoga) {
        justify-content: flex-end;
    }
}

.socialIcon {
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 6px 6px 0 0;
    font-size: 1.3rem;
    cursor: pointer;
    &.yoga {
        margin: 6px 0 0 6px;
        color: getColorValue(1, (56, 36, 242));
        background-color: getColorValue(2, (255, 255, 255));
    }
    &:not(.yoga) {
        margin: 6px 6px 0 0;
        color: white;
        background-color: rgba(0, 0, 0, 0.75);
    }
}
