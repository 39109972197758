// WARNING: This stylesheet is being used in multiple files. Exercise caution while making changes.

// TODO - @aditya: Move this stylesheet inside the <template_name> folder directly and update imports accordingly.

@import "@Static/css/common";

// theme colours - keep all colors(even commented ones)
// for reference for subcomponents
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));
$color4: getColorValue(3, (51, 51, 51), 0.75);

.root {
    min-height: 100dvh;
    overflow-y: overlay;
    @extend .themedScrollbar;
    width: 100%;
    background-color: $color1;
    display: flex;
    flex-direction: column;
    color: $color3;
    font-weight: 500;
    font-size: 0.9rem;
    background-color: $color2;
    line-height: 1.4;
    overflow-x: hidden;
}
.mediaListContainer {
    margin-bottom: 120px !important;
}
@media only screen and (max-width: 800px) {
    .uniformWidth {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1479px) {
    .uniformWidth {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media only screen and (min-width: 1480px) {
    .uniformWidth {
        margin-left: calc(((100vw - 1480px) / 2) + 3rem);
        margin-right: calc(((100vw - 1480px) / 2) + 3rem);
    }
}

.sectionContainer {
    margin-bottom: 4rem;
    overflow-x: hidden;
}

.smallSectionContainer {
    margin-bottom: 1rem;
}

.sectionAnchor {
    position: relative;
    top: -60px;
}

@media only screen and (min-width: 601px) {
    .bigText {
        font-size: 2rem;
        font-weight: 900;
    }
}

@media only screen and (max-width: 601px) {
    .bigText {
        font-size: 1.5rem;
        font-weight: 900;
    }
}

.sectionTitle {
    @extend .bigText;
    margin-bottom: 0.5rem;
}

.sectionDescription {
    color: $color4;
}

.borderRadius {
    border-radius: 4px;
}
