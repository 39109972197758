@use "sass:map";
@use "sass:string";

// Styling to be kept here
// Mixins, functions
// Styles that will be imported but not used as globals

.word-break {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
}

// TODO: Move to a separate file
@mixin colorProperty(
    $propName,
    $key,
    $props,
    $globalDefault,
    $parentKey,
    $fallbackParentKey
) {
    // value is evaluated in this manner:
    // 1. var from backend - constructed from `$key` & `map.get($prop, "parentKey")`
    // 2. local default used while calling the mixin in a component
    // 3. global default defined in the mixin
    $prop: map.get($props, $propName);
    @if $prop {
        $default: map.get($prop, "color") or $globalDefault;
        $varName: getVarName($key, $parentKey);
        $color: null;
        @if $fallbackParentKey {
            $fallbackVarName: getVarName($key, $fallbackParentKey);
            $color: var($varName, var($fallbackVarName, $default));
        } @else {
            $color: var($varName, $default);
        }
        $opacityVarName: getOpacityVarName(map.get($prop, "opacityKey"));
        $opacity: var($opacityVarName, map.get($prop, "opacity") or 1);

        $val: rgba($color, $opacity);
        @if $propName == "tc" {
            color: $val;
        } @else if $propName == "bgc" {
            background-color: $val;
        } @else if $propName == "boc" {
            border-color: $val;
        }
    }
}

@function getOpacityVarName($key) {
    $varName: "--" + $key + "-" + "customOpacities-host-theme";
    @return string.unquote($varName);
}

@function getVarName($key, $parentKey) {
    $varName: "--" + "rgbColor" + $key + "-";
    $parentKey: $parentKey or "schema1";
    $varName: $varName + $parentKey;
    @return string.unquote($varName + "-host-theme");
}

// theme combinations
// each color combination will have following props:
// ("tc": text-color, "bgc": background-color, "boc": border-color)
// each prop can have these values present:
// color: (r, g, b)
// opacity: 0 to 1
// by default all props are added
// if you want to only specific set of props - only send those in the mixin call
// eg. colorCombination2("bgc": ()) - this will apply only background color prop
// parentKey: parentKey in the backend theme JSON

// 1: Primary (CTA) color
// 2: Secondary (background) color
// 3: Tertiary (text) color
@mixin colorCombination1(
    $props: (
        "tc": (),
        "bgc": (),
        "boc": (),
    ),
    $parentKey: null,
    $fallbackParentKey: null
) {
    @include colorProperty(
        "tc",
        "1",
        $props,
        (255, 255, 255),
        $parentKey,
        $fallbackParentKey
    );
    @include colorProperty(
        "bgc",
        "2",
        $props,
        (34, 34, 34),
        $parentKey,
        $fallbackParentKey
    );
    @include colorProperty(
        "boc",
        "1",
        $props,
        (255, 255, 255),
        $parentKey,
        $fallbackParentKey
    );
}
@function getColorValue(
    $key,
    $localDefault: null,
    $opacity: null,
    $parentKey: null
) {
    // for color props which are not independent
    // eg: box-shadow, svg fill, individual border colors(top/bottom/left/right), gradients;
    $default: $localDefault or (237, 72, 83);
    $key: $key or "1";
    $color: var(getVarName($key, $parentKey), $default);

    @if $opacity {
        @return rgba($color, $opacity);
    }
    @return rgb($color);
}

@mixin colorCombination2(
    $props: (
        "tc": (),
        "bgc": (),
        "boc": (),
    ),
    $parentKey: null,
    $fallbackParentKey: null
) {
    @include colorProperty(
        "tc",
        "2",
        $props,
        (34, 34, 34),
        $parentKey,
        $fallbackParentKey
    );
    @include colorProperty(
        "bgc",
        "1",
        $props,
        (255, 255, 255),
        $parentKey,
        $fallbackParentKey
    );
    @include colorProperty(
        "boc",
        "2",
        $props,
        (34, 34, 34),
        $parentKey,
        $fallbackParentKey
    );
}

@mixin colorCombinationXYZ(
    $props: (
        "tc": (),
        "bgc": (),
        "boc": (),
    ),
    $parentKey: null,
    $fallbackParentKey: null
) {
    $key1: map.get(map.get($props, "tc") or (), "key") or 1;
    $key2: map.get(map.get($props, "bgc") or (), "key") or 2;
    $key3: map.get(map.get($props, "boc") or (), "key") or 1;
    @include colorProperty(
        "tc",
        $key1,
        $props,
        (34, 34, 34),
        $parentKey,
        $fallbackParentKey
    );
    @include colorProperty(
        "bgc",
        $key2,
        $props,
        (255, 255, 255),
        $parentKey,
        $fallbackParentKey
    );
    @include colorProperty(
        "boc",
        $key3,
        $props,
        (34, 34, 34),
        $parentKey,
        $fallbackParentKey
    );
}

// use these mixins to control the border radius
@mixin borderRadius1($default: 10000000000px) {
    // If the value exceeds half of the shortest side's length,
    // the browser will use the minimum as its border-radius in both directions,
    // producing a perfect pill shape on rectangular elements.
    border-radius: var(--borderRadius1-default-host-theme, $default);
}

.plainScrollbar {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 5px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(114, 114, 114, 0.3);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
}

.plainScrollbarWBorder {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #777;
        border: 1px solid #888;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        background-color: #f8f7fa;
    }
}

.themedScrollbar {
    scrollbar-color: var(--rgbColor1-schema1-host-theme, #f47354);
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        // TODO: Get this reviewed by @mayank
        width: 10px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                ),
            )
        );
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                ),
            ),
            "schema1"
        );
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 2,
                ),
            ),
            "schema1"
        );
    }
}

// TODO: Implement this common mixin everywhere
@mixin aspectRatio(
    $width,
    $aspect-ratio: 1,
    $minWidth: 100px,
    $maxWidth: 100vw
) {
    // The css below ensures that the video element is always
    // according to the aspect ratio, no matter the screen size
    aspect-ratio: #{$aspect-ratio};

    width: clamp(#{$minWidth}, #{$width}, #{$maxWidth});
    height: clamp(
        #{$minWidth} / #{$aspect-ratio},
        #{$width / $aspect-ratio},
        #{$maxWidth} / #{$aspect-ratio}
    );
}
