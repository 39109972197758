@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$cardBgColor: rgb(var(--template-secondary-color-host-theme, 255, 255, 255));
$cardTextColor: getColorValue(3, (0, 0, 0));

.container {
    background-color: $cardBgColor;
    border-radius: 5px;
    min-width: 200px;
    width: 350px;
    max-width: calc(100vw - 40px);
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 20px;
    line-height: 1.6;
    box-shadow: #ccc 0px 2.5px 5px;
    cursor: pointer;
    transition: 0.2s ease all;
    position: relative;

    &:hover {
        box-shadow: #ccc 0px 3px 6px;
        transform: translateY(-4px) scale(1.01);
    }
}

.imageContainer {
    position: relative;
}

.image {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    background-color: #ddd;

    width: 350px;
    height: 202px;

    max-width: calc(100vw - 40px);
    max-height: calc(202 / 350 * (100vw - 40px));

}

.labelsContainer {
    position: absolute;
    top: 10px;
    margin-left: 10px;
    display: flex;
    font-size: 0.7rem;
}

.label {
    padding: 2px 6px;
    margin-right: 6px;
    border-radius: 5%;
    border-radius: 4px;
}

.detailsContainer {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 8px;
    color: $cardTextColor;
}
.amount {
    z-index: 1;
    width: 100%;
    margin-top: -70px;
}
.shortDescription {
    font-size: 14px;
    color: $cardTextColor;
    opacity: 60%;
}

.price {
    box-shadow: $primaryColor 0px 3px 6px;
    margin-top: -10px;
    font-size: 0.95rem;
    font-weight: 700;
    float: right;
    width: 80px;
    height: 80px;
    background-color: $primaryColor;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;
}
.divider {
    color: #eee;
    opacity: 60%;
    margin: 20px 0px 16px;
}
.ctaButton {
    width: 100%;
    background-color: $cardBgColor;
    color: $cardTextColor;
    font-size: 16px;
    padding: 7px;
    border-radius: 5px;
    transition: 0.2s ease all;
    &:hover {
        opacity: 0.9;
    }
}

.titleContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}