@import "@Static/css/common";

$color1: getColorValue(1, (56, 36, 242), 0.06);

.root {
    padding-bottom: 4rem;
}

@media only screen and (max-width: 600px) {
    .root {
        padding-bottom: 1rem;
    }
}