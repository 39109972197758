// WARNING: This stylesheet is being used in multiple files. Exercise caution while making changes.

// TODO - @aditya: Move this stylesheet inside the <template_name> folder directly and update imports accordingly.

@import "@Static/css/common";

$textColor: getColorValue(1, (255, 255, 255));
$bgColor: getColorValue(2, (10, 18, 30));
$secondaryTextColor: getColorValue(3, (0, 0, 0));

.sectionTitle {
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0.74px;
    opacity: 1;
    color: $secondaryTextColor;
}

.root {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    overflow-y: overlay;
    overflow-x: hidden;
    color: $textColor;
    background-color: $bgColor;
    @extend .themedScrollbar;
    scroll-behavior: smooth;
}

.navbar {
    position: sticky !important;
    top: 0 !important;
}

.offeringPageEventSectionContainer {
    padding-top: 4rem;
}

@media (max-width: 600px) {
    .sectionTitle {
        font-size: 28px;
        line-height: 39px;
        letter-spacing: 0.45px;
    }
}
