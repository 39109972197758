@import "@Static/css/common";

$textColor: getColorValue(3, (0, 0, 0));

.sectionTitle {
    width: 100%;
    text-align: left;
    font-size: 46px;
    line-height: 1.2;
    font-weight: bold;
    color: $textColor;
    padding: 6px 20px 24px;
}

@media only screen and (max-width: 600px) {
    .sectionTitle {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.4;
        padding: 6px 12px 6px;
    }
}
